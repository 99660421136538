import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReplacePipe } from "./replace.pipe";
import { ReplaceWithHTMLTagPipe } from "./replaceWithHTMLTag.pipe";
import { ReplaceExcerptPipe } from "./replaceExcerpt.pipe";
import { SafePipe } from "./safe.pipe";
import { SafeImagePipe } from "./safeImage.pipe";
import { HighlightUrlPipe } from "./highlightUrl.pipe";
import { EscapeOpeningAngleBracketsPipe } from "./escapeOpeningAngleBracket";
import { HighlightKeywordsPipe } from "./highlight-keywords.pipe";
import { ObscureEmailPipe } from "./obscure-email.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [
    ReplacePipe,
    ReplaceWithHTMLTagPipe,
    ReplaceExcerptPipe,
    SafePipe,
    SafeImagePipe,
    HighlightUrlPipe,
    EscapeOpeningAngleBracketsPipe,
    HighlightKeywordsPipe,
    ObscureEmailPipe,
  ],
  exports: [
    ReplacePipe,
    ReplaceWithHTMLTagPipe,
    ReplaceExcerptPipe,
    SafePipe,
    SafeImagePipe,
    HighlightUrlPipe,
    EscapeOpeningAngleBracketsPipe,
    HighlightKeywordsPipe,
    ObscureEmailPipe,
  ],
})
export class PipesModule {}
