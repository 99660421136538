import { Auth } from "@aws-amplify/auth";
import gql from "graphql-tag";
import { Injectable } from "@angular/core";
import { API, graphqlOperation } from "aws-amplify";
import { AuthService } from "src/app/shared/services/auth.service";
import { ConfigService } from "src/app/shared/services/config.service";
import { LoggingLevel } from "src/generated/graphql";

@Injectable({
  providedIn: "root"
})

export class ClientErrorService {

  private logEventMutation = gql`
    mutation logEvent($source: LoggingSource!, $level: LoggingLevel!, $message: String!, $data: AWSJSON) {
      logEvent(source: $source, level: $level, message: $message, data: $data) {
        success
      }
    }
  `;

  constructor(
    private configService: ConfigService,
    private authService: AuthService,
  ) { }

  private getToken(): Promise<any> {
    return Auth.currentSession().then(session => {
      return session.getIdToken().getJwtToken()
    });
  }

  public logClientEvent(level: LoggingLevel, message: string, eventData: any): void {

    const data = eventData !== null ? eventData : <any>{};
    data.workerUsername = this.authService.signedIn() ? this.authService.getUsername() : "unauthenticated user";
    data.timeOfError = new Date().toISOString();

    this.getToken().then(token => {

      API.configure({
        aws_appsync_graphqlEndpoint: this.configService.getConfig().graphQLApiUrl,
        aws_appsync_region: this.configService.getConfig().region,
        aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
        graphql_headers: {
          Authorization: token,
        }
      });

      API.graphql(graphqlOperation(
        this.logEventMutation, {
          level: level,
          source: "L1UI",
          message: message,
          data: JSON.stringify(data),
        },
      ));

    });

    switch (level) {
      case LoggingLevel.Error:
        console.error(message, eventData);
        break;
      case LoggingLevel.Warning:
        console.warn(message, eventData);
        break;
      case LoggingLevel.Info:
        console.info(message, eventData);
        break;
      case LoggingLevel.Debug:
        console.debug(message, eventData);
    }
  }

}
