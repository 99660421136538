import { Injectable } from "@angular/core";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { environment } from "src/environments/environment";
import { WorkItem } from "src/generated/graphql";

const L1_REVIEW_CATEGORY = "l1-review-event";

enum L1Event {
  resolved = "l1-unblock",
  escalate = "l1-escalate",
  delete = "l1-delete",
  skip = "l1-skip",
}

enum L1Label {
  text = "text",
  image = "image",
  mixed = "mixed",
  grid = "grid",
  url = "url",
  unknown = "unknown",
}

function getLabel(...workItems: WorkItem[]): L1Label {
  if (!workItems) {
    return L1Label.unknown;
  }
  if (workItems.length > 1) {
    return L1Label.grid;
  }
  let hasText: boolean, hasImage: boolean, hasUrl: boolean;

  if (workItems.length === 0) {
    return L1Label.unknown;
  }

  workItems[0].blockReasons?.forEach((blockReason) => {
    if (blockReason.cacheItem?.url) {
      hasImage = true;
    }
    if (blockReason.excerpts?.length > 0) {
      hasText = true;
    }
    if (blockReason.reason?.startsWith("Offensive or pornographic image detected at url")) {
      hasUrl = true;
    }
  });

  if (hasImage && hasText) {
    return L1Label.mixed;
  }
  if (hasText) {
    return L1Label.text;
  }
  if (hasImage) {
    return L1Label.image;
  }
  if (hasUrl) {
    return L1Label.url;
  }

  return L1Label.unknown;
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private prodMode: boolean;
  private startTime: Date;
  private itemsInProgress: number;
  private label: L1Label;
  constructor(private $gaService: GoogleAnalyticsService) {
    this.prodMode = environment.enableProdMode;
  }

  startWork(...workItems: WorkItem[]): void {
    if (this.itemsInProgress > 0) {
      this.recordEvents(...Array(this.itemsInProgress).fill(L1Event.skip));
    }
    this.startTime = new Date();
    this.itemsInProgress = workItems.length;
    this.label = getLabel(...workItems);
  }
  stopWork(...events: string[]): void {
    if (this.itemsInProgress <= 0) {
      return;
    }
    this.itemsInProgress = events.length;
    this.recordEvents(...events.map((event) => L1Event[event]));
    this.startTime = null;
    this.itemsInProgress = 0;
    this.label = null;
  }

  private getWorkTimeInSeconds(): number {
    const wallTimeInSeconds = (new Date().getTime() - this.startTime.getTime()) / 1000;
    const workTimeInSeconds = Math.round(wallTimeInSeconds / this.itemsInProgress);
    return workTimeInSeconds;
  }

  private recordEvents(...events: L1Event[]) {
    if (events === undefined) {
      return;
    }

    const category = L1_REVIEW_CATEGORY;
    events?.forEach((event) => {
      if (this.prodMode) {
        this.$gaService.event(event, category, this.label, this.getWorkTimeInSeconds());
      } else {
        console.log(
          `!!! -->>> action=${event} ` + `category=${category} ` + `label=${this.label} ` + `time=${this.getWorkTimeInSeconds()}`
        );
      }
    });
    this.startTime = null;
  }
}
