import { Injectable, ErrorHandler } from "@angular/core";
import { CwRumService } from "src/app/shared/services/cw-rum.service";

/**
 * This very simple class has to exist so that we can inject the CwRumService
 * into the ErrorHandler. If we try to use the CwRumService directly as the
 * ErrorHandler, it will cause the service to be instantiated twice, one for
 * the ErrorHandler and one the APP_INITIALIZER. This simply creates a wrapper
 * around the CwRumService so that we can inject it into the ErrorHandler.
 */

@Injectable({
  providedIn: "root",
})
export class CwRumErrorService implements ErrorHandler {
  constructor(private cwRumService: CwRumService) {}
  handleError(error: any): void {
    this.cwRumService.handleError(error);
  }
}
