import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appEmptyAttribute]",
})
// Directive for creating empty attributes on elements created
// by ngFor. This is primarily used for creating unique selenium selectors.
export class EmptyAttributeDirective implements OnInit {
  @Input("appEmptyAttribute") name: string;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.setAttribute(this.name, "");
  }
}
