<mat-toolbar data-testid="navigation" class="navbar" [ngClass]="{ 'side-nav': showSideBar }" (closeNavigation)="closeSidenav()">
  <div class="toggle-nav-container">
    <button data-test-on-toggle-side-nav data-testid="toggle-side-nav" mat-icon-button (click)="onToggleSidenav()" class="nav-toggle">
      <mat-icon class="icon">menu</mat-icon>
    </button>
  </div>
  <nav>
    <ul class="app-menu">
      <li [ngClass]="{ selected: selectedTab === 'Review'}"><a data-test-review-link class="menu-selected" routerLink="/" (click)="closeSidenav()">Review</a></li>
      <li>
        <a
          data-test-click-training
          href="https://sites.google.com/gaggle.net/engagementmaterial/home"
          target="_blank"
          (click)="closeSidenav()"
          >Enrollment Material</a
        >
      </li>
    </ul>
    <div class="user-menu" *ngIf="authenticated">
      <button
        mat-icon-button
        class="new-application-button"
        *ngIf="newApplicationAvailable"
        (click)="getLatestApplication()"
        color="warn"
        matTooltip="Get the latest version of L1UI">
        <mat-icon>auto_mode</mat-icon>
      </button>
      <div class="username" data-cy="username">{{ welcomeMessage }}</div>
      <gaggle-icon
        iconName="signout"
        data-testid="sign-out-link"
        iconSize="1em"
        routerLink
        (click)="signOut()"
        class="logout-link"
      ></gaggle-icon>
    </div>
  </nav>
</mat-toolbar>
