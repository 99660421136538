import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "escapeOpeningAngleBrackets" })
export class EscapeOpeningAngleBracketsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const stringToReplace = value.toString();

    return stringToReplace.replace(new RegExp("<", "g"), "&lt;");
  }
}
