import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "obscureEmail",
})
export class ObscureEmailPipe implements PipeTransform {
  emailRegex = new RegExp("([a-zA-Z0-9._-]+)@([a-zA-Z0-9._-]+\\.[a-zA-Z0-9_-]+)", "ig");

  transform(excerpt: string): string {
    return excerpt.replace(this.emailRegex, (m) => {
      const [username, domain] = m.split("@");
      return "*".repeat(username.length) + `@${domain}`;
    });
  }
}
