import { Component, HostListener, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Platform } from "@angular/cdk/platform";
import { StateService } from "src/app/shared/services/state.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { SwUpdateService } from "src/app/shared/services/sw-update.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit {
  public showBackToTop = false;
  isUnsupportedBrowser = false;
  authenticated = false;
  loading = false;
  allowKeyboardInteractions = false;

  constructor(
    private platform: Platform,
    private state: StateService,
    private authService: AuthService,
    private appSync: AppSyncService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private swUpdateService: SwUpdateService,
  ) {}

  @ViewChild("updateDialog", { static: true }) updateDialog: TemplateRef<any>;

  @HostListener("window:keydown", ["$event"]) disableKeyboard(event: KeyboardEvent): void {
    if (!this.allowKeyboardInteractions) {
      if (event.code === "Enter" || event.code === "Space") {
        this.snackBar.open(`You cannot take action using the ${event.code} key.`, "", {
          panelClass: ["mat-snack-error"],
          verticalPosition: "top",
          duration: 1500,
        });
        event.preventDefault();
      }
    }
  }

  ngOnInit(): void {
    this.isUnsupportedBrowser = !this.platform.BLINK;
    this.state.showFullPageLoadingSpinner$.subscribe((loading) => {
      this.loading = loading;
    });

    this.state.allowKeyboardInteractions$.subscribe((allow) => {
      this.allowKeyboardInteractions = allow;
    });

    this.state.authenticated$.subscribe((authenticated) => {
      this.authenticated = authenticated;

      if (authenticated) {
        // get worker throughput every 5 minutes
        setInterval(() => {
          // this.appSync.getWorkerThroughput();
          this.state.markProcessingSpeed();
        }, 5 * 60 * 1000);
      }
    });

    // Set the timer to automatically check for updates
    this.swUpdateService.pollForUpdates();
  }

  public scrollToTop(): void {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event: any): void {
    this.showBackToTop = $event.target.documentElement.scrollTop > 300;
  }

  get authenticationMessage(): string {
    return this.loading ? "Attempting log in..." : "You must be logged in to use this application.";
  }
}
