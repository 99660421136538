<div *ngIf="isUnsupportedBrowser" class="error-container">
  <p class="error-text">
    This application is not supported in this browser. Please close this browser and open the application in a Gaggle-approved browser.
  </p>
</div>
<div class="page-wrap" *ngIf="!isUnsupportedBrowser">
  <app-navbar></app-navbar>
  <!--main-->
  <main>
    <ng-container *ngIf="authenticated; else unauthenticated">
      <router-outlet></router-outlet>
    </ng-container>
    <ng-template #unauthenticated>
      <div class="error-container">
        <p class="error-text">{{ authenticationMessage }}</p>
      </div>
    </ng-template>
  </main>

  <app-footer></app-footer>
  <div *ngIf="showBackToTop" class="back-to-top transition" (click)="scrollToTop()">
    <mat-icon data-testid="scroll-arrow">arrow_upward</mat-icon>
  </div>
</div>

<ng-template #updateDialog>
  <h2 matDialogTitle>Application Update Available</h2>
  <span
    class="update-dialog-text"
    matDialogContent
  >There is a new version of this application available. You can refresh now or when convenient by using the icon located at the top right of the
        page.</span
  >
  <mat-dialog-actions class="update-dialog-actions">
    <button
      mat-button
      [mat-dialog-close]="true">
      Refresh Now
    </button>
    <button
      mat-button
      [mat-dialog-close]="false">
      Refresh Later
    </button>
  </mat-dialog-actions>
</ng-template>
