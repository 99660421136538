import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { WorkItem } from "src/app/models/work-items.model";
import { DownloadInfo, MessageMetadata } from "src/app/models/message-metadata.model";
import { FileCommentContent } from "src/generated/graphql";

@Injectable({
  providedIn: "root",
})
export class StateService {
  private spinnerTimer: NodeJS.Timeout;
  private spinnerCount = 0;

  private readonly _environment = new BehaviorSubject<string>("local");
  readonly environment$ = this._environment.asObservable();

  private readonly _newApplicationAvailable = new BehaviorSubject<boolean>(false);
  readonly newApplicationAvailable$ = this._newApplicationAvailable.asObservable();

  private readonly _authenticated = new BehaviorSubject<boolean>(false);
  readonly authenticated$ = this._authenticated.asObservable();

  private readonly _showFullPageLoadingSpinner = new BehaviorSubject<boolean>(false);
  readonly showFullPageLoadingSpinner$ = this._showFullPageLoadingSpinner.asObservable();

  private readonly _currentItemDetails = new BehaviorSubject<WorkItem[]>(null);
  readonly currentItemDetails$ = this._currentItemDetails.asObservable();

  private readonly _downloadLink = new BehaviorSubject<DownloadInfo>(null);
  readonly downloadLink$ = this._downloadLink.asObservable();

  private readonly _additionalWorkItems = new BehaviorSubject<WorkItem[]>([]);
  readonly additionalWorkItems$ = this._additionalWorkItems.asObservable();

  private readonly _fileComments = new BehaviorSubject<FileCommentContent>(null);
  readonly fileComments$ = this._fileComments.asObservable();

  private readonly _messageMetadata = new BehaviorSubject<MessageMetadata>(null);
  readonly messageMetadata$ = this._messageMetadata.asObservable();

  private readonly _imageGridOnly = new BehaviorSubject<boolean>(false);
  readonly imageGridOnly$ = this._imageGridOnly.asObservable();

  private readonly _appSyncError = new BehaviorSubject<string>("");
  readonly appSyncError$ = this._appSyncError.asObservable();

  private readonly _processingSpeed = new BehaviorSubject<number>(-1);
  readonly processingSpeed$ = this._processingSpeed.asObservable();

  private _allowKeyboardInteractions$ = new BehaviorSubject<boolean>(false);
  public allowKeyboardInteractions$ = this._allowKeyboardInteractions$.asObservable();

  constructor() {}

  resetCurrentItemState(): void {
    this.downloadLink = null;
    this.appSyncError = "";
    this.messageMetadata = null;
  }

  get showFullPageLoadingSpinner(): boolean {
    return this._showFullPageLoadingSpinner.getValue();
  }

  set showFullPageLoadingSpinner(val: boolean) {
    this._showFullPageLoadingSpinner.next(val);
  }

  set allowKeyboardInteractions(val: boolean) {
    this._allowKeyboardInteractions$.next(val);
  }

  useFullPageSpinner(incrementUsage: boolean): void {
    if (incrementUsage) {
      this.spinnerCount++;
      if (this.spinnerCount === 1) {
        clearInterval(this.spinnerTimer);
        this.spinnerTimer = setInterval(() => {
          clearInterval(this.spinnerTimer);
          this.spinnerCount = 0;
        }, 15000);
      }
    } else {
      this.spinnerCount--;
      if (this.spinnerCount < 0) {
        this.spinnerCount = 0;
      }
      if (this.spinnerCount === 0) {
        clearInterval(this.spinnerTimer);
      }
    }
    this.showFullPageLoadingSpinner = this.spinnerCount > 0;
  }

  get environment(): string {
    return this._environment.getValue();
  }

  set environment(val: string) {
    this._environment.next(val);
  }

  set newApplicationAvailable(val: boolean) {
    this._newApplicationAvailable.next(val);
  }

  get authenticated(): boolean {
    return this._authenticated.getValue();
  }

  set authenticated(val: boolean) {
    this._authenticated.next(val);
  }

  get additionalWorkItems(): WorkItem[] {
    return this._additionalWorkItems.getValue();
  }

  set additionalWorkItems(val: WorkItem[]) {
    this._additionalWorkItems.next(val);
  }

  get downloadLink(): DownloadInfo {
    return this._downloadLink.getValue();
  }

  set downloadLink(val: DownloadInfo) {
    this._downloadLink.next(val);
  }

  get fileComments(): FileCommentContent {
    return this._fileComments.getValue();
  }

  set fileComments(val: FileCommentContent) {
    this._fileComments.next(val);
  }

  get messageMetadata(): MessageMetadata {
    return this._messageMetadata.getValue();
  }

  set messageMetadata(val: MessageMetadata) {
    this._messageMetadata.next(val);
  }

  get imageGridOnly(): boolean {
    return this._imageGridOnly.getValue();
  }

  set imageGridOnly(val: boolean) {
    this._imageGridOnly.next(val);
  }

  get appSyncError(): any {
    return this._appSyncError.getValue();
  }

  set appSyncError(val: any) {
    this._appSyncError.next(val);
  }

  get processingSpeed(): number {
    return this._processingSpeed.getValue();
  }

  set processingSpeed(val: number) {
    this._processingSpeed.next(val);
  }

  incrementItemsProcessed(val = 1): void {
    const count = parseInt(localStorage.getItem("processedItemCount") || "0") + val
    localStorage.setItem("processedItemCount", ""+count);
  }

  markProcessingSpeed(): void {
    this.processingSpeed = parseInt(localStorage.getItem("processedItemCount") || "0");
    localStorage.setItem("processedItemCount", "0");
  }
}
