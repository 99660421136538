import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "replaceExcerpt" })
export class ReplaceExcerptPipe implements PipeTransform {
  transform(excerpt: string, keyword: string): string {
    if (!excerpt) {
      return "";
    }

    if (!keyword) {
      return excerpt;
    }

    const keywordUpperCase = keyword.toUpperCase();
    const excerptUpperCase = excerpt.toUpperCase();

    const matcher = this.escapeRegex(keywordUpperCase);
    return excerptUpperCase.replace(new RegExp(matcher, "g"), '<b class="highlightText">' + keywordUpperCase + "</b>");
  }

  // Sometimes keywords have special chars in them, for example 's***'.
  // These characters need to be escaped in order to highlight them.
  escapeRegex(inputString: string): string {
    return inputString.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  }
}
