/**
 * Enumeration of feature flags to be retrieved from Split.io.
 *
 * All flags in this enumeration will be fetched from Split.io
 * every two minutes.
 *
 * NOTE: We manage these here, rather than in the schema due
 * to the custom values that are being mapped. Appsync
 * does not allow for value mapping in an enumeration set
 *
 */
export enum FeatureFlagName {
  APPSYNC_URL_OVERRIDE = "thndr-appsync-url-override",
}

export enum FeatureFlagValue {
  NOT_SET = "control",
  ON = "on",
  OFF = "off",
}
