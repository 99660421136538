import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { QueueStatisticsGQL } from "src/generated/graphql";
import { StateService } from "src/app/shared/services/state.service";

@Component({
  selector: "app-unauthenticated",
  templateUrl: "./unauthenticated.component.html",
  styleUrls: ["./unauthenticated.component.scss"],
})
export class UnauthenticatedComponent implements OnInit {

  networkError: HttpErrorResponse;

  constructor(
    private queueStatisticsGQL: QueueStatisticsGQL,
    private stateService: StateService,
  ) {}

  ngOnInit(): void {
    this.queueStatisticsGQL.fetch()
      .subscribe(
        res => res,
        err => {
          this.stateService.useFullPageSpinner(false)
          this.networkError = err.networkError
        }
      )
  }

  get success(): boolean {
    return this.networkError && this.networkError.status === 401;
  }

}
