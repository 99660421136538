import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.enableProdMode) {
  enableProdMode();
}

function fadeLoadingElement() {
  // Clear the loading timer, so the application isn't reloaded automatically
  window.clearInterval(window["loadingInterval"]);

  // Fade out the loading element
  const loadingElement = document.querySelector(".loading-wrapper");
  if (loadingElement) {
    setTimeout(() => {
      loadingElement.classList.add("app-loaded");
      // Remove the element after the transition
      setTimeout(() => {
        loadingElement.remove();
      }, 1000);
    }, 1000);
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => fadeLoadingElement())
  .catch((err) => console.error(err));
