import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[closeNavigation]",
})
export class CloseNavigationDirective {
  initialClick = true;
  @Input() ignoredElementIds: string[] = [];
  @Output() closeNavigation: EventEmitter<any> = new EventEmitter();

  eventIsIgnoredElement(event: any): boolean {
    const isFoundArray = this.ignoredElementIds.map((ignoredId) => {
      const renderedElement = document.getElementById(ignoredId);
      if (!renderedElement) {
        return true;
      } else {
        return renderedElement.contains(event.target);
      }
    });
    return isFoundArray.some((found) => found);
  }

  @HostListener("document:click", ["$event"])
  onClick(event: any): void {
    if (this.initialClick) {
      this.initialClick = false;
      return;
    } else {
      if (!this.elemRef.nativeElement.contains(event.target) && !this.eventIsIgnoredElement(event)) {
        this.closeNavigation.emit();
      }
    }
  }

  constructor(private elemRef: ElementRef) {}
}
