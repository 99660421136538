// This is the environment settings file for running L1UI in the staging (Dev-Legacy) AWS environment

export const environment = {
  enableProdMode: false,
  name: "staging",
  configUrl: null,
  useServiceWorker: true,
  useDataDog: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
