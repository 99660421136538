import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";

import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { UnauthenticatedComponent } from "./pages/unauthenticated/unauthenticated.component";

const routes: Routes = [
  {
    path: "connection-test",
    component: UnauthenticatedComponent,
  },
  {
    path: "paused",
    component: PagesComponent,
    children: [{ path: "", loadChildren: () => import("./pages/paused-worker/paused-worker.module").then((m) => m.PausedWorkerModule) }],
  },
  {
    path: "",
    component: PagesComponent,
    children: [{ path: "", loadChildren: () => import("./pages/review/review.module").then((m) => m.ReviewModule) }],
  },
  { path: "**", component: NotFoundComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  relativeLinkResolution: "legacy",
  urlUpdateStrategy: "eager",
});
