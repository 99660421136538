import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface ModalDialogData {
  title: string;
  content: string;
  dismissButtonText?: string;
  okButtonText?: string;
}

@Component({
  selector: "app-modal-dialog",
  templateUrl: "./modal-dialog.component.html",
  styleUrls: ["./modal-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalDialogData) {}
}
