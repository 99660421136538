<h2 mat-dialog-title data-testid="dialog-title">{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
    <p data-testid="dialog-content">{{ data.content }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        data-testid="dismiss-button"
        *ngIf="data.dismissButtonText"
        mat-button
        mat-dialog-close>
        {{ data.dismissButtonText }}
    </button>
    <button
        data-testid="ok-button"
        *ngIf="data.okButtonText"
        mat-stroked-button
        [mat-dialog-close]="true"
        cdkFocusInitial>
        {{ data.okButtonText }}
    </button>
</mat-dialog-actions>
