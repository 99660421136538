import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "highlightUrl" })
export class HighlightUrlPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    let result = value.toString();
    const reg = "(https?|ftp|www.)://[^\\s]*";

    result = result.replace(new RegExp(reg, "g"), (match) => `<a href="${match}" class="highlightText" target="_blank">${match}</a>`);

    return result;
  }
}
