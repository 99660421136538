import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { StateService } from "src/app/shared/services/state.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "gsm-ui";
  showFullPageSpinner = false;

  constructor(
    private authService: AuthService,
    private state: StateService,
  ) {}

  ngOnInit(): void {
    this.state.showFullPageLoadingSpinner$.subscribe((val) => (this.showFullPageSpinner = val));

    // this pathname must match the unauthenticated route specified in `app.routing.ts`
    if (window.location.pathname !== "/connection-test") {
      this.authService.initialize();
    }

  }
}
