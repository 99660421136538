import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, APP_INITIALIZER, InjectionToken, ErrorHandler } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { PlatformModule } from "@angular/cdk/platform";
import { SharedModule } from "src/app/shared/shared.module";
import { routing } from "src/app/app.routing";
import { AppComponent } from "src/app/app.component";
import { FooterComponent } from "src/app/theme/components/footer/footer.component";
import { NavbarComponent } from "src/app/theme/components/navbar/navbar.component";
import { PagesComponent } from "src/app/pages/pages.component";
import { NotFoundComponent } from "src/app/pages/not-found/not-found.component";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { AppSyncService } from "src/app/shared/services/appsync.service";
import { GaggleFullPageSpinnerComponent } from "./controls/gaggle-full-page-spinner/gaggle-full-page-spinner.component";
import { GaggleUiCommonModule } from "gaggle-ui-common";
import { UnauthenticatedComponent } from "./pages/unauthenticated/unauthenticated.component";
import { AppSyncClientService } from "./shared/services/appsync-client.service";
import { AnalyticsService } from "./shared/services/analytics.service";
import { CloseNavigationDirective } from "./shared/directives/close-navigation.directive";
import { GaggleApolloModule } from "src/app/graphql/gaggle-apollo.module";
import { ConfigFactory, ConfigService } from "src/app/shared/services/config.service";
import { SplitService } from "@splitsoftware/splitio-angular";
import { AuthService } from "src/app/shared/services/auth.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "src/environments/environment";
import { SwUpdateFactory, SwUpdateService } from "src/app/shared/services/sw-update.service";
import { ModalDialogComponent } from "src/app/controls/modal-dialog/modal-dialog.component";
import { CwRumFactory, CwRumService } from "src/app/shared/services/cw-rum.service";
import { CwRumErrorService } from "src/app/shared/services/cw-rum-error.service";

/**
 * This is a custom injection token that will allow chaining of APP_INITIALIZERs
 * that are dependent on each other. This is necessary because the SplitFactory
 * requires the ConfigService to be initialized first so that it can get the
 * Split auth key from the config.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const ConfigDeps = new InjectionToken<(() => Function)[]>("configDeps");

function SplitFactory(configService: ConfigService, splitService: SplitService): () => Promise<any> {
  return (): Promise<any> => {
    const sdkConfig = {
      core: {
        authorizationKey: configService.getConfig().splitIoAuthKey,
        key: "key",
      },
    };

    return new Promise((resolve) => {
      try {
        splitService.init(sdkConfig).subscribe({
          next: () => {
            resolve(true);
          },
        });
      } catch (error) {
        console.error(error);
        resolve(true);
      }
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    PagesComponent,
    NotFoundComponent,
    GaggleFullPageSpinnerComponent,
    ModalDialogComponent,
    CloseNavigationDirective,
    UnauthenticatedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PlatformModule,
    routing,
    SharedModule,
    NgxGoogleAnalyticsModule.forRoot("UA-65741934-1"),
    GaggleUiCommonModule,
    GaggleApolloModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.useServiceWorker === true,
      registrationStrategy: "registerImmediately",
    }),
  ],
  providers: [
    AuthService,
    AppSyncService,
    AppSyncClientService,
    AnalyticsService,
    CwRumService,
    { provide: APP_INITIALIZER, useFactory: SwUpdateFactory, deps: [SwUpdateService], multi: true },
    { provide: APP_INITIALIZER, useFactory: ConfigFactory, deps: [ConfigService, ConfigDeps], multi: true },
    {
      // Use a factory that returns an array of the dependencies that need to be loaded before the
      // app can start but not until we've gathered the config from the config service
      provide: ConfigDeps,
      useFactory: (configService: ConfigService, splitService: SplitService, cwRumService: CwRumService) => {
        return [SplitFactory(configService, splitService), CwRumFactory(configService, cwRumService)];
      },
      deps: [ConfigService, SplitService, CwRumService],
    },
    { provide: ErrorHandler, useClass: CwRumErrorService },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
