import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import Amplify from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import { StateService } from "./state.service";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { BehaviorSubject } from "rxjs";

enum JWTPayloadProperty {
  L1UI_USERID = "custom:userId",
  WORKER_UUID = "custom:workerUUID",
  COGNITO_USERNAME = "cognito:username",
  WORKER_FULL_NAME = "custom:workerFullName",
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private username: string;
  private name: string;
  private gaggleUser = false;
  private identityProviderId: string;
  private workerId: string;
  private userPoolClientId: string;
  private refreshTokenValue: string = null;
  private tokenIsRefreshing = false;
  private tokenRefreshPromise: Promise<void>;
  private refreshTokenResolve: () => void;

  private _jwtToken$ = new BehaviorSubject<string>(null);
  public jwtToken$ = this._jwtToken$.asObservable();

  constructor(private configService: ConfigService, private stateService: StateService, private $gaService: GoogleAnalyticsService) {}

  public initialize(): void {
    const config = this.configService.getConfig();

    this.stateService.useFullPageSpinner(true);
    this.$gaService.set("dimension10", config.version);
    this.identityProviderId = config.identityProviderId;
    this.userPoolClientId = config.userPoolWebClientId;
    this.stateService.environment = config.environment;

    Amplify.configure({
      Auth: {
        region: config.region,
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.userPoolWebClientId,
        mandatorySignIn: false,
      },
    });

    Auth.configure({
      oauth: {
        domain: config.userPoolDomain,
        scope: ["openid", "email", "profile"],
        redirectSignIn: config.redirectSignIn,
        redirectSignOut: config.redirectSignOut,
        responseType: "code",
      },
    });

    Auth.currentAuthenticatedUser()
      .then(() => {
        Auth.currentSession().then((session) => {
          this.updateUserInfoFromSession(session);
          this.stateService.useFullPageSpinner(false);
        });
      })
      .catch((error) => {
        this.clearUserData();
        this.stateService.useFullPageSpinner(false);
        if (error === "The user is not authenticated") {
          void Auth.federatedSignIn({ customProvider: this.identityProviderId });
        } else {
          console.error(error);
        }
      });
  }

  public refreshToken(): Promise<void> {
    if (!this.tokenIsRefreshing) {
      this.tokenIsRefreshing = true;
      this.tokenRefreshPromise = new Promise((resolve) => {
        this.refreshSession().then(() => {
          this.tokenIsRefreshing = false;
          resolve();
        });
      });
    }
    return this.tokenRefreshPromise;
  }

  private async refreshSession(): Promise<void> {
    try {
      await Auth.currentSession().then((session) => {
        this._jwtToken$.next(session.getIdToken().getJwtToken());
      });
    } catch (error) {
      console.error(`Failed to refresh session: ${error}`);
      this.signOut();
      if (this.identityProviderId) {
        void Auth.federatedSignIn({ customProvider: this.identityProviderId });
      }
    }
  }

  updateUserInfoFromSession(session: any): void {
    const sessionIdToken = session.getIdToken();
    this._jwtToken$.next(sessionIdToken.getJwtToken());
    const jwtPayload = sessionIdToken.payload;

    this.stateService.imageGridOnly = this.determineImageGridOnlyAccess(jwtPayload);
    this.stateService.authenticated = true;
    this.refreshTokenValue = session.getRefreshToken().getToken();
    this.gaggleUser = JWTPayloadProperty.WORKER_UUID in jwtPayload;

    if (this.gaggleUser) {
      this.workerId = jwtPayload[JWTPayloadProperty.WORKER_UUID];
    } else {
      this.workerId = jwtPayload[JWTPayloadProperty.L1UI_USERID];
    }

    if (jwtPayload.identities !== undefined) {
      this.username = jwtPayload.identities[0].userId;
    } else {
      this.username = jwtPayload[JWTPayloadProperty.COGNITO_USERNAME];
    }

    if (jwtPayload[JWTPayloadProperty.WORKER_FULL_NAME]) {
      this.name = jwtPayload[JWTPayloadProperty.WORKER_FULL_NAME];
    } else if (jwtPayload.name) {
      this.name = jwtPayload.name;
    } else {
      this.name = this.username;
    }

    this.$gaService.set("dimension5", this.workerId);
  }

  private clearUserData(): void {
    this.username = null;
    this.gaggleUser = false;
    this.name = null;
    this.workerId = null;
    this._jwtToken$.next(null);
    this.stateService.authenticated = false;
  }

  public signedIn(): boolean {
    return this.stateService.authenticated;
  }

  public signOut(): void {
    this.clearUserData();
    void Auth.signOut();
  }

  public getUsername(): string {
    return this.username;
  }

  public getName(): string {
    return this.name;
  }

  public getAuthToken(): string {
    return this._jwtToken$.value;
  }

  public getWorkerId(): string {
    return this.workerId;
  }

  public getRefreshToken(): string {
    return this.refreshTokenValue;
  }

  public getUserPoolClientId(): string {
    return this.userPoolClientId;
  }

  private determineImageGridOnlyAccess(payload): boolean {
    const options = payload["custom:options"];
    if (options) {
      const imageGridOn = (options & 16) === 16;
      const imageOn = (options & 2) === 2;
      return imageOn ? false : imageGridOn;
    }
    return false;
  }
}
