import { Component, Input } from "@angular/core";

@Component({
  selector: "app-gaggle-full-page-spinner",
  templateUrl: "./gaggle-full-page-spinner.component.html",
  styleUrls: ["./gaggle-full-page-spinner.component.scss"],
})
export class GaggleFullPageSpinnerComponent {
  @Input() showSpinner: boolean;

  constructor() {}
}
