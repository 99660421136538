import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { StateService } from "src/app/shared/services/state.service";
import { LogoutGQL } from "src/generated/graphql";
import { SwUpdate } from "@angular/service-worker";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  showSideBar = false;
  authenticated = false;
  newApplicationAvailable = false;
  selectedTab = "Review";

  constructor(
    private authService: AuthService,
    private state: StateService,
    private logoutGQL: LogoutGQL,
    private swUpdate: SwUpdate,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.state.authenticated$.pipe(takeUntil(this.unsubscribe$)).subscribe((authenticated) => {
      this.authenticated = authenticated;
    });

    this.state.newApplicationAvailable$.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => {
      this.newApplicationAvailable = val;
    });

    this.activatedRoute.url.pipe(takeUntil(this.unsubscribe$)).subscribe((url) => {
      // If there is no url, then we are on the review tab
      if (url.length === 0) {
        this.selectedTab = "Review";
      } else {
        this.selectedTab = null;
      }
    });
  }

  onToggleSidenav(): void {
    this.showSideBar = !this.showSideBar;
  }

  closeSidenav(): void {
    if (this.showSideBar) {
      this.showSideBar = false;
    }
  }

  getLatestApplication(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  signOut(): void {
    this.logoutGQL
      .mutate({
        refreshToken: this.authService.getRefreshToken(),
        userPoolClientId: this.authService.getUserPoolClientId(),
      })
      .subscribe(() => {
        this.authService.signOut();
      });
  }

  get welcomeMessage(): string {
    return `Welcome, ${this.authService.getName()}`;
  }
}
