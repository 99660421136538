import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private configUrl = environment.configUrl !== null ? environment.configUrl : "/assets/config.json";
  private config: Config;

  constructor(
    private httpClient: HttpClient
  ) {}

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<Config>(this.configUrl)
        .subscribe(config => {
          this.config = config;
          resolve(true);
        }, error => {
          console.error(error);
          reject(true);
        });
    })
  }

  getConfig(): Config {
    return this.config;
  }
}

export function ConfigFactory(
  config: ConfigService,
  // eslint-disable-next-line @typescript-eslint/ban-types
  configDeps: (() => Function)[]
): () => Promise<any> {
  return (): Promise<any> => {
    return config.load().then(() => {
      return Promise.all(configDeps.map((dep) => dep()));
    });
  };
}

export interface Config {
  userPoolId: string;
  userPoolWebClientId: string;
  userPoolDomain: string;
  identityProviderId: string;
  graphQLApiUrl: string;
  redirectSignIn: string;
  redirectSignOut: string;
  deleteButtonHidden: string;
  featureFlagRefreshSeconds: string;
  version: string;
  environment: string;
  splitIoAuthKey: string;
  region: string;
  cwrIdentityPoolId: string;
  cwrApplicationId: string;
}
