import { NgModule } from "@angular/core";
import { ApolloLink, DefaultOptions, InMemoryCache, fromPromise } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { APOLLO_FLAGS, APOLLO_NAMED_OPTIONS, ApolloModule, NamedOptions } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { AuthService } from "src/app/shared/services/auth.service";
import { ClientErrorService } from "src/app/shared/services/client-error.service";
import { ConfigService } from "src/app/shared/services/config.service";
import { StateService } from "src/app/shared/services/state.service";
import { LoggingLevel } from "src/generated/graphql";
import { SplitService } from "@splitsoftware/splitio-angular";
import { FeatureFlagName, FeatureFlagValue } from "src/app/models/feature-flags.model";

function createApollo(
  httpLink: HttpLink,
  configService: ConfigService,
  authService: AuthService,
  clientErrorService: ClientErrorService,
  stateService: StateService,
  splitService: SplitService,
): NamedOptions {
  const config = configService.getConfig();
  const urlOverride = splitService.getTreatment(FeatureFlagName.APPSYNC_URL_OVERRIDE, {
    username: authService.getUsername(),
  });

  let baseApiUrl = config.graphQLApiUrl;
  if (urlOverride !== FeatureFlagValue.NOT_SET && urlOverride !== FeatureFlagValue.OFF) {
    baseApiUrl = `https://${urlOverride}.appsync-api.us-west-2.amazonaws.com/graphql`;
  }

  const uriWithOperationName = (operation) => baseApiUrl + "?operationName=" + operation.operationName;

  const httpLinkHandler = httpLink.create({ uri: uriWithOperationName });

  const basicHeaders = setContext(() => ({
    headers: {
      Accept: "charset=utf-8",
    },
  }));

  /**
   * This link will automatically manage the spinner count in the state service to ensure
   * that all backend calls that should trigger the spinner do. When the call returns, this
   * will ensure that the count is updated appropriately, automatically hiding the spinner
   * when the last backend call completes.
   */
  const statusLink = new ApolloLink((operation, forward) => {

    /**
     * Any operations specified in this array will not trigger the status indicator
     */
    const bypassSpinner = operation.getContext().bypassSpinner;
    if (!bypassSpinner) {
      stateService.useFullPageSpinner(true);
    }

    return forward(operation).map((data) => {
      if (!bypassSpinner) {
        stateService.useFullPageSpinner(false);
      }
      return data;
    });
  });

  const authHeaders = setContext(() => {
    const token = authService.getAuthToken();
    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `${token}`,
        },
      };
    }
  });

  const apiKeyHeaders = setContext(() => {
    return {
      headers: {
        "x-api-key": "invalidApiKey",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    // Handle 401 error specifically with a token refresh
    if (networkError && networkError["status"] === 401) {
      return fromPromise(authService.refreshToken()).flatMap(() => forward(operation));
    } else {
      // Handle other network errors
      if (networkError) {
        clientErrorService.logClientEvent(LoggingLevel.Error, networkError.message, networkError);
      }

      // Handle the ML errors individually
      if (operation.getContext().isKmmEvent) {
        graphQLErrors.map((error) => {
          clientErrorService.logClientEvent(LoggingLevel.Error, error.message, {
            error,
            dataSent: operation.variables,
            attention: "ML",
          });
        });
      } else {
        if (graphQLErrors) {
          graphQLErrors.map((error) => {
            if (error) {
              clientErrorService.logClientEvent(LoggingLevel.Error, error.message, error);
            }
          });
        }
      }

      return forward(operation);
    }
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 500,
      max: Infinity,
    },
    attempts: {
      max: 3,
    },
  });

  const defaultLink = ApolloLink.from([basicHeaders, statusLink, retryLink, errorLink, authHeaders, httpLinkHandler]);

  const unauthedApiLink = ApolloLink.from([basicHeaders, apiKeyHeaders, httpLinkHandler]);

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  };

  return {
    default: {
      link: defaultLink,
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
    },
    noAuth: {
      link: unauthedApiLink,
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ConfigService, AuthService, ClientErrorService, StateService, SplitService],
    },
    { provide: APOLLO_FLAGS, useValue: { useInitialLoading: true } },
  ],
})
export class GaggleApolloModule {}
