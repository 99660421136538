import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlightKeywords",
})
export class HighlightKeywordsPipe implements PipeTransform {
  transform(value: string, keywords: string[] = []): string {
    let result = value.toString();

    const uniqueKeywords = Array.from(new Set(keywords.map((keyword) => keyword?.toUpperCase()))).filter((v) => v);

    if (uniqueKeywords.length) {
      uniqueKeywords.forEach((keyword) => {
        const escapedKeyword = keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        const regex = new RegExp(escapedKeyword, "ig");

        result = result.replace(regex, (match) => `<span class="highlightText">${match}</span>`);
      });
    }

    return result;
  }
}
