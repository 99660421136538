import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "replaceWithHTMLTagPipe" })
export class ReplaceWithHTMLTagPipe implements PipeTransform {
  transform(value: string, strToReplace: string, replacementStr: string): string {
    let counter = 0;
    let result = value;

    if (result) {
      while (result.includes(strToReplace)) {
        if (counter % 2 == 0) {
          result = result.replace(strToReplace, "<" + replacementStr + ">");
        } else {
          result = result.replace(strToReplace, "</" + replacementStr + ">");
        }
        counter++;
      }
    }

    return result;
  }
}
